/* eslint-disable  react/jsx-props-no-spreading */
import React, { useState, useMemo } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import get from 'lodash.get';
import { Trans } from 'react-i18next';

import { article as ArticlePropType } from 'lib/CustomPropTypes';
import { verticalSlugMap } from 'lib/vertical';
import { getDataActivityMapID } from 'lib/articleUtils';
import {
  MYNEWS_ENABLED,
  ARTICLE_INLINE_NEWSLETTER,
  USER_ACTIONS_BELOW_ARTICLE,
  UNIVERSAL_CHECKOUT_WELLS_FARGO_BANNER,
  SHOW_USER_ACTIONS,
  ARTICLE_TAXONOMY_SECTION_NUMBERING,
} from 'lib/brandFeatures';
import { getFeatureConfigForBrand } from 'lib/getFeatureStatus';
import { AccountLoginRegistration } from 'components/AccountLoginRegistration';
import {
  AUTHENTICATED,
  UNAUTHENTICATED,
  INITIALIZED,
  UNINITIALIZED,
  UNKNOWN,
} from 'lib/myNewsConstants';
import {
  isBlogArticle,
  isShellArticle,
  shouldShowAd,
} from 'lib/article';
import { withAccountWorkflows } from 'lib/Hooks/useAccountWorkflows';
import { logError } from 'lib/datadog';
import { withGateAccess } from 'lib/Hooks/withGateAccess';
import { useGeoLocation } from 'lib/Hooks';
import { useMyNewsStore } from 'store';
import { VIEW } from 'lib/view';
import Ad from 'components/Ad';
import { BackToTopButton } from 'components/BackToTopButton';
import { LiveBlogPosts } from 'components/LiveBlogPosts';
import NewsletterSignupInline from 'components/NewsletterSignup/Inline';
import { RecirculationBacon } from 'components/RecirculationBacon';
import { SocialShareMenu } from 'components/SocialShareMenu';
import MostPopularStoryList from 'components/MostPopularStoryList';
import { Gate } from 'components/Gate';
import { GATE_TITLES } from 'components/Gate/GateTextConstants';
import { ErrorBoundary } from 'components/ErrorBoundary';
import { WellsFargoBanner } from 'components/WellsFargoBanner';
import Related from 'components/Article/Related';
import { FeaturedRecipes } from 'components/Article/FeaturedRecipes';
import { extractTermPaths } from 'lib/taxonomy';
import { getAuthenticationState, isBedrockApiEnabled } from 'lib/authenticationUtils';
import { ExpandedBylineContributors } from '../ExpandedBylineContributors';
import { ArticleFoot } from '../ArticleFoot';
import { getNormalizedArticleBody } from '../articleUtils';
import { getSections } from '../getSections';
import { BodyRightRail } from './BodyRightRail';
import { BodyTaboola } from './BodyTaboola';
import { BodyBottomRecommended } from './BodyBottomRecommended';
import { SocialAndSponsored } from './SocialAndSponsored';
import './styles.themed.scss';
import { StandardByline } from './StandardByline';
import { useSaveArticleHistory } from './hooks/useSaveArticleHistory';
import { useArticleScroll } from './hooks/useArticleScroll';
import { boxflexAdRenderCheck } from './bodyUtils';

const block = 'article-body';

const {
  news: NEWS,
  today: TODAY,
} = verticalSlugMap;

/**
 * Maps state to props for the ArticleBody component.
 * @param {object} state - The state from the Redux store.
 * @param {object} state.article - The article state.
 * @param {object} state.myNews - The myNews state.
 * @returns {object} The mapped props.
 */
const mapStateToProps = ({ article, myNews }) => ({
  contentId: get(article, 'content[0].id', ''),
  myNews,
});

/**
 * ArticleBody component renders the body of an article.
 * @param {object} props - The properties object.
 * @param {Article} props.article - The article object.
 * @param {string} props.contentId - The content ID.
 * @param {boolean} [props.showCreatedDate=true] - Flag to show the created date.
 * @param {string} props.vertical - The vertical type.
 * @param {boolean} [props.showUserActions=MYNEWS_ENABLED.default] - Flag to show user actions.
 * @param {boolean} [props.showBylineTimestamp=true] - Flag to show byline timestamp.
 * @param {boolean} [props.showInlineByline=true] - Flag to show inline byline.
 * @param {boolean} [props.isLiveBlog=false] - Flag to indicate if it is a live blog.
 * @param {string} [props.bylineTimestampDatePublished=undefined] - The byline timestamp date published.
 * @param {boolean} [props.tableOfContentsEnabled=false] - Flag to enable table of contents.
 * @param {string} [props.backToTopBtnsAlignDesktop='top'] - Alignment of back to top buttons on desktop.
 * @param {string} [props.backToTopBtnsAlignMobile='top'] - Alignment of back to top buttons on mobile.
 * @param {string} props.path - The current path.
 * @param {Function} [props.getRightRailAdConfig=null] - Function to get right rail ad config.
 * @param {boolean} [props.shouldRenderRightRailTaboola=null] - Flag to render right rail Taboola.
 * @param {boolean} [props.isChromeless=false] - Flag to indicate if it is chromeless.
 * @param {string} [props.view=null] - The view type.
 * @param {boolean} [props.isShoppable=false] - Flag to indicate if it is shoppable.
 * @param {boolean} [props.taboolaRecoReelEnabled=false] - Flag to enable Taboola Reco Reel.
 * @param {string} [props.className=null] - Additional class name.
 * @param {boolean} [props.disableGrid=false] - Flag to disable grid.
 * @param {boolean} [props.shouldRenderTaboolaFeed=true] - Flag to render Taboola feed.
 * @param {object} props.gateAccess - The gate access object.
 * @param {Array} [props.mostPopularStoryListItems=[]] - List of most popular story items.
 * @param {Array} [props.relatedArticles=[]] - List of related articles.
 */
export function ArticleBody(props) {
  const {
    article,
    contentId,
    showCreatedDate = true,
    vertical,
    showUserActions = MYNEWS_ENABLED.default,
    showBylineTimestamp = true,
    showInlineByline = true,
    isLiveBlog = false,
    bylineTimestampDatePublished = undefined,
    tableOfContentsEnabled = false,
    backToTopBtnsAlignDesktop = 'top',
    backToTopBtnsAlignMobile = 'top',
    path: currentPath,
    getRightRailAdConfig = null,
    shouldRenderRightRailTaboola = null,
    isChromeless = false,
    view = null,
    isShoppable = false,
    path,
    taboolaRecoReelEnabled = false,
    className = null,
    disableGrid = false,
    shouldRenderTaboolaFeed = true,
    gateAccess,
    mostPopularStoryListItems = [],
    relatedArticles = [],
  } = props;


  const articleCanonicalUrl = article?.url?.canonical;
  const [boxflexRendered, setBoxflexRendered] = useState(false);
  const saveHistory = useMyNewsStore((state) => state.saveHistory);
  const savedContentId = useMyNewsStore((state) => state.savedContentId);
  const authenticationState = getAuthenticationState();
  const { isUsa } = useGeoLocation();
  const isNewLoginFlow = isBedrockApiEnabled();

  const ads = [];
  const {
    isScrolledPastFirstWindow,
    isScrollingUp,
    isInView,
  } = useArticleScroll({
    article,
    ads,
  });

  useSaveArticleHistory({
    authenticationState,
    savedContentId,
    article,
    saveHistory,
    articleId: article?.id,
  });
  const articleHasEmbeddedTaxonomy = useMemo(() => article?.body.find((item) => item.type === 'embeddedTaxonomy') || null, [article?.body]);

  if (!article) {
    return null;
  }

  const {
    ecommerceEnabled,
    headline,
    source,
    taxonomy,
    url,
    breakingNews,
    breakingTagDisabled,
    primaryImage,
    primaryMedia,
    adsEnabled,
    nativeAd,
  } = article;

  const renderLiveBlogPost = isLiveBlog && articleHasEmbeddedTaxonomy;

  /**
   * Gets the developing story tag for the article.
   * @returns {React.ReactNode} The developing story tag.
   */
  const getDevelopingStoryTag = () => {
    if (!breakingNews || isLiveBlog || breakingTagDisabled) {
      return null;
    }

    return (
      <div
        className="articleDevelopingTag"
        data-testid="article-developing-tag"
      >
        <div className="articleDevelopingTag__stripe-border">
          <span className="articleDevelopingTag__stripe-border--solid db" />
        </div>
        <div className="articleDevelopingTag__content">
          <Trans i18nKey="developingStory">
            This is a
            <span className="articleDevelopingTag__content--red"> developing </span>
            story. Please check back for updates.
          </Trans>
        </div>
      </div>
    );
  };


  /**
     * Gets the expanded byline for the article.
     * @returns {React.ReactNode} The expanded byline.
     */
  const getExpandedByline = () => {
    if (isShellArticle(article)) {
      return null;
    }

    return (
      <ExpandedBylineContributors
        article={article}
        additionalClasses="articleBylineContainer"
        bylineAdditionalClasses="article-expanded-byline"
        data-test="article-byline"
      />
    );
  };

  /**
     * Gets the CSS classes for the body container.
     * @returns {object} The CSS classes.
     */
  const getBodyContainerClasses = () => {
    const conditionalClasses = {
      'article-body__grid--container-no-main-image': !primaryImage && !primaryMedia,
      'article-body__ecommerce-enabled': ecommerceEnabled,
      'article-body__grid--container-live-blog': isLiveBlog,
    };

    const hasArticleSectionCounter = getFeatureConfigForBrand(
      ARTICLE_TAXONOMY_SECTION_NUMBERING,
      vertical,
    );

    if (Array.isArray(hasArticleSectionCounter)) {
      const termPaths = extractTermPaths(taxonomy);
      const hasMatchingClass = hasArticleSectionCounter.filter((tax) => termPaths.includes(tax));
      conditionalClasses['article-body__section-counter'] = hasMatchingClass.length > 0;
    }

    return conditionalClasses;
  };

  /**
   * Determines if the Today Gift Guide should be rendered.
   * @returns {boolean} True if the Today Gift Guide should be rendered, false otherwise.
   */
  const shouldRenderTodayGiftGuide = () => {
    const subTypeIsNotLiveBlogOrBlog = !isBlogArticle(article);

    return ecommerceEnabled && vertical === TODAY && subTypeIsNotLiveBlogOrBlog;
  };

  /**
   * Registers an ad element.
   * @param {HTMLElement} el - The element to register.
   */
  const registerAd = (el) => {
    const ad = el && el.querySelector('.article-body__right-rail--ad');
    if (ad) {
      ads.current = [...ads, ad];
    }
  };

  /**
   * Renders the interstitial ad if applicable.
   * @returns {React.ReactNode|null} The interstitial ad component or null.
   */
  const interstitialAd = () => {
    if (shouldShowAd(article) && [NEWS, TODAY].includes(vertical)) {
      return (
        <Ad
          slot="interstitial"
          refreshInterval={0}
        />
      );
    }

    return null;
  };

  /**
 * Renders the right rail section of the article.
 * @param {object} params - The parameters.
 * @param {object} params.section - The section object.
 * @param {number} params.sectionIndex - The index of the section.
 * @param {boolean} params.articleContainsLargeProduct - Indicates if the article contains a large product.
 * @returns {React.ReactNode} The right rail section.
 */
  const renderRightRail = ({
    section, sectionIndex, articleContainsLargeProduct,
  }) => (
    <div
      className={`${block}--right-rail-container`}
      ref={(el) => registerAd(el)}
    >
      <BodyRightRail
        block={block}
        section={section}
        sectionIndex={sectionIndex}
        articleContainsLargeProduct={articleContainsLargeProduct}
        shouldRenderRightRailTaboola={shouldRenderRightRailTaboola}
        getRightRailAdConfig={getRightRailAdConfig}
        currentPath={currentPath}
        boxflexRendered={boxflexRendered}
        isScrollingUp={isScrollingUp}
        showTaboola={isInView}
        boxflexAdRenderCheck={(mpsAd) => boxflexAdRenderCheck({
          mpsAd,
          setBoxflexRendered,
        })}
      />
    </div>
  );

  // split up for sonarcube smell test on readability
  const isNotShellArticle = !isShellArticle(article);

  const hasSocialsBelowArticle = showUserActions
        && getFeatureConfigForBrand(USER_ACTIONS_BELOW_ARTICLE, vertical);
  const hasSocialMenuBelowArticle = isNotShellArticle
        && hasSocialsBelowArticle;

  const articleBody = getNormalizedArticleBody(article);
  const articleContainsLargeProduct = isShoppable || !!articleBody.find(
    ({ type, presentation }) => (type === 'embeddedProduct' && presentation.size === 'large'),
  );
  const articleContainsUniCheckout = !!articleBody.find(
    ({ type }) => (type === 'embeddedProduct'),
  );
  const isPrimarySectionFood = (article?.taxonomy?.primarySection?.name ?? '').toLowerCase() === 'food';

  const embeddedRecipes = articleBody.filter((item) => item.type === 'embeddedRecipe');
  const shouldShowFeaturedRecipes = embeddedRecipes && embeddedRecipes.length > 1
      && isPrimarySectionFood;

  const sections = getSections({
    adsEnabled,
    article,
    articleBody,
    breakingNews,
    ecommerceEnabled,
    isShoppable,
    isLiveBlog,
    nativeAd,
    path,
    taboolaRecoReelEnabled,
    vertical,
    gateAccess,
  });

  const sectionsIndex = sections.map((section, index) => ({ index, ...section }));
  const sectionLast = sections.length - 1;

  const gridContainerClasses = classNames(
    'article-body__grid--container',
    getBodyContainerClasses(),
    {
      'article_body__grid--shell-container': isShellArticle(article),
      // class is targeted in shell article pages
      'article-body__gridContainer': isShellArticle(article),
    },
  );

  const gridBodyClasses = classNames(
    { 'layout-grid-item layout-grid-item--with-gutter-s-only grid-col-10-m grid-col-push-1-m grid-col-6-xl grid-col-push-2-xl': !disableGrid },
    { 'article-body--custom-column': !isShellArticle(article) && !disableGrid },
  );

  const bodyGridClasses = classNames(
    'article-body',
    gridBodyClasses,
  );
  const bodyFullWidthClasses = 'article-body layout-grid-item';

  const dataTaboolaTarget = !isChromeless && !isLiveBlog && gateAccess ? 'read-more' : '';

  const shouldRenderBackToTopButton = isScrolledPastFirstWindow
      && !isLiveBlog
      && ecommerceEnabled;

  const dataActivityMapID = getDataActivityMapID({
    pageRegion: 'article-body',
    componentName: 'article',
  });

  const isNews = vertical === NEWS;
  const userActions = getFeatureConfigForBrand(SHOW_USER_ACTIONS, vertical);
  const { showPinterest } = userActions || {};

  const isShell = article.presentation.style === 'shell';
  const isStartToday = view === VIEW.START_TODAY_APP;

  const showMostPopular = isNews
      && !breakingNews
      && !isChromeless
      && !nativeAd
      && !isShell
      && !ecommerceEnabled;

  const shouldRenderRelated = isNotShellArticle
      && relatedArticles?.length >= 3
      && !isStartToday
      && !isChromeless;

  /**
     * Renders the article body sections.
     * @returns {React.ReactNode} The rendered article body sections.
     */
  const renderArticleBody = () => (
    sectionsIndex.map((section, i) => (
      <div
        key={section.index}
        className={classNames(
          { 'article-body__section layout-grid-container': (!disableGrid && !section.isFullWidth) },
          {
            'article-body__last-section': i === sections.length - 1,
            'article-body__first-section': i === 0,
          },
        )}
      >
        {articleContainsUniCheckout
            && getFeatureConfigForBrand(UNIVERSAL_CHECKOUT_WELLS_FARGO_BANNER, vertical)
            && <WellsFargoBanner bodyClasses={bodyGridClasses} />}
        <div
          className={section.isFullWidth ? bodyFullWidthClasses : bodyGridClasses}
          data-test="articleBody"
        >
          {i === 0 && (
            <StandardByline
              {...{
                isLiveBlog,
                showUserActions,
                showBylineTimestamp,
                showInlineByline,
                vertical,
                bylineTimestampDatePublished,
                article,
                contentId,
                block,
                tableOfContentsEnabled,
                showCreatedDate,
                isChromeless,
              }}
            />
          )}
          {shouldShowFeaturedRecipes
            ? <FeaturedRecipes embeddedRecipes={embeddedRecipes} /> : null}

          <div className="article-body__content">
            {section.items}
          </div>
          {i === sectionLast
              && isLiveBlog
              && hasSocialMenuBelowArticle && (
            <SocialShareMenu
              url={url.primary}
              headline={headline.tease}
              contentId={contentId}
              trackingEventName="article_social_share_bottom"
              actions={{ pinterest: showPinterest }}
            />
          )}
          <div className="article-body__content">
            {i === sectionLast
                && (breakingNews || isLiveBlog)
                && (
                  <>
                    {renderLiveBlogPost && (
                      <ErrorBoundary errorLogger={logError}>
                        <LiveBlogPosts
                          articleCanonicalUrl={articleCanonicalUrl}
                          backToTopBtnsAlignDesktop={backToTopBtnsAlignDesktop}
                          backToTopBtnsAlignMobile={backToTopBtnsAlignMobile}
                        />
                      </ErrorBoundary>
                    )}
                    {getDevelopingStoryTag()}
                    {getFeatureConfigForBrand(ARTICLE_INLINE_NEWSLETTER, vertical)
                      && vertical !== 'today'
                      && <NewsletterSignupInline article={article} vertical={vertical} />}
                  </>
                )}
            {i === sectionLast && (
              <>
                {getExpandedByline()}
                {hasSocialMenuBelowArticle && !isLiveBlog && !isStartToday ? (
                  <div className="article-social-share-bottom">
                    <SocialShareMenu
                      url={url.primary}
                      headline={headline.tease}
                      contentId={contentId}
                      pageRegion="article-bottom"
                      trackingEventName="article_social_share_bottom"
                      actions={{ pinterest: showPinterest }}
                    />
                  </div>
                ) : null}
              </>
            )}
          </div>

        </div>
        {renderRightRail({
          section, sectionIndex: i, articleContainsLargeProduct,
        })}
      </div>
    ))
  );

  /**
     * Renders the gated article body.
     * @returns {React.ReactElement} The gated article body.
     */
  const renderGatedArticleBody = () => {
    const section = sectionsIndex[0];
    const showLogin = isUsa && isNewLoginFlow && !isChromeless;
    const showGateOrLogin = showLogin ? (
      <AccountLoginRegistration layout="fluid" />
    ) : (
      <Gate title={GATE_TITLES.article} />
    );
    return (
      <div
        className="article-body__gate article-body__first-section article-body__section layout-grid-container"
        data-testid="gated-article-body"
      >
        <div
          data-test="articleBody"
          className={bodyGridClasses}
        >
          <StandardByline
            {...{
              isLiveBlog,
              showUserActions,
              showBylineTimestamp,
              showInlineByline,
              vertical,
              bylineTimestampDatePublished,
              article,
              contentId,
              block,
              tableOfContentsEnabled,
              showCreatedDate,
            }}
          />
          <div
            className={
              classNames(
                'article-body__content',
                'article-body__content--gated',
              )
            }
          >
            {/* Render section components */}
            {section.items}
          </div>
          <div className="gated-content--wrapper">
            {showGateOrLogin}
          </div>
        </div>
        {renderRightRail({
          section: sections[0], sectionIndex: 0, articleContainsLargeProduct,
        })}
      </div>
    );
  };

  return (
    <>
      <div
        className={classNames(
          block,
          className,
          {
            breaking: breakingNews,
            'is-live-blog': isLiveBlog,
          },
        )}
        data-activity-map={dataActivityMapID}
        data-taboola-target={dataTaboolaTarget}
        data-testid="article-body"
      >
        {shouldRenderBackToTopButton && <BackToTopButton />}
        <div className={gridContainerClasses}>
          {interstitialAd()}
          <SocialAndSponsored
            {...{
              article,
              bodyClasses: bodyGridClasses,
              nativeAd,
              source,
            }}
          />
          {gateAccess ? renderArticleBody() : renderGatedArticleBody()}


          {!isShoppable && (
            <RecirculationBacon pageRegion="article-bottom" />
          )}

          <BodyBottomRecommended
            block={block}
            currentPath={path}
            gridBodyClasses={gridBodyClasses}
            shouldRenderTodayGiftGuide={shouldRenderTodayGiftGuide()}
            showTaboola={isInView}
          />
        </div>
        {showMostPopular && (
          <MostPopularStoryList
            mostPopularStoryListItems={mostPopularStoryListItems}
          />
        )}
      </div>

      <ArticleFoot vertical={vertical} currentPath={path} article={article} />

      {shouldRenderTaboolaFeed && (
        <BodyTaboola
          article={article}
          showTaboola={isInView}
        />
      )}
      {shouldRenderRelated ? (
        <Related
          article={article}
          relatedArticles={relatedArticles}
          vertical={vertical}
          view
        />
      ) : null}
    </>
  );
}

ArticleBody.propTypes = {
  article: ArticlePropType.isRequired,
  contentId: PropTypes.string.isRequired,
  getRightRailAdConfig: PropTypes.func,
  isChromeless: PropTypes.bool,
  view: PropTypes.string,
  isShoppable: PropTypes.bool,
  path: PropTypes.string.isRequired,
  shouldRenderRightRailTaboola: PropTypes.func,
  shouldRenderTaboolaFeed: PropTypes.bool,
  taboolaRecoReelEnabled: PropTypes.bool,
  showCreatedDate: PropTypes.bool,
  taxonomy: PropTypes.shape({
    primarySection: PropTypes.shape({
      path: PropTypes.string,
    }),
  }),
  vertical: PropTypes.string.isRequired,
  className: PropTypes.string,
  disableGrid: PropTypes.bool,
  showUserActions: PropTypes.bool,
  showBylineTimestamp: PropTypes.bool,
  showInlineByline: PropTypes.bool,
  backToTopBtnsAlignDesktop: PropTypes.oneOf(['top', 'bottom']),
  backToTopBtnsAlignMobile: PropTypes.oneOf(['top', 'bottom']),
  isLiveBlog: PropTypes.bool,
  gateAccess: PropTypes.bool.isRequired,
  mostPopularStoryListItems: PropTypes.arrayOf(PropTypes.shape({})),
  relatedArticles: PropTypes.arrayOf(PropTypes.shape({})),
  saveHistory: PropTypes.func.isRequired,
  savedContentId: PropTypes.string,
  mparticleId: PropTypes.string,
  authenticationState: PropTypes.oneOf([
    AUTHENTICATED,
    UNAUTHENTICATED,
    INITIALIZED,
    UNINITIALIZED,
    UNKNOWN,
  ]),
  bylineTimestampDatePublished: PropTypes.string,
  tableOfContentsEnabled: PropTypes.bool,
};

export default connect(mapStateToProps)(
  withAccountWorkflows(withGateAccess(ArticleBody)),
  useMyNewsStore,
);

