import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import {
  shouldRenderEcommerceRecommendations,
  shouldShowAd,
} from 'lib/article';
import { ArticleContext, VerticalContext } from 'lib/ContextTypes';
import { verticalSlugMap } from 'lib/vertical';

import MidresponsiveAd from 'components/Ad/Midresponsive';
import Recommended from 'components/Recommended';

const {
  news: NEWS,
  today: TODAY,
} = verticalSlugMap;

/**
 * BodyBottomRecommended component to display recommended content at the bottom of the article body.
 *
 * @param {object} props - The component props.
 * @param {string} [props.block=''] - The block class name.
 * @param {string} [props.gridBodyClasses=''] - The grid body classes.
 * @param {boolean} [props.shouldRenderTodayGiftGuide=false] - Indicates if the Today Gift Guide should be rendered.
 * @param {boolean} [props.showTaboola=false] - Indicates if Taboola should be shown.
 * @returns {React.ReactNode} The rendered component.
 */
export const BodyBottomRecommended = ({
  block = '',
  gridBodyClasses = '',
  shouldRenderTodayGiftGuide = false,
  showTaboola = false,
}) => {
  // Using method instead of imported function for test mocks
  const article = useContext(ArticleContext);
  const vertical = useContext(VerticalContext);

  const url = article?.url ?? {};
  const additionalTerms = article?.taxonomy?.additionalTerms ?? [];
  const primaryLabelName = article?.taxonomy?.primaryLabel?.name ?? '';
  const primarySectionName = article?.taxonomy?.primarySection?.name ?? '';

  const hasOnTheShowLabel = useMemo(() => {
    const isAnyAdditionalLabelOnTheShow = additionalTerms.find((label) => label?.name?.toLowerCase() === 'on the show');
    const isPrimaryLabelOnTheShow = primaryLabelName.toLowerCase() === 'on the show';
    return isAnyAdditionalLabelOnTheShow || isPrimaryLabelOnTheShow;
  }, [additionalTerms, primaryLabelName]);

  const renderEcommerceWidget = shouldRenderEcommerceRecommendations(article, vertical);

  const gridBodyDnLClasses = useMemo(() => classNames(gridBodyClasses, 'dn-l'), [gridBodyClasses]);

  const gridBodyBotRailRecommendedClasses = useMemo(() => classNames(gridBodyClasses, block, `${block}__bottom-rail--recommended`), [gridBodyClasses, block]);


  const isPrimarySectionShop = useMemo(() => primarySectionName.toLowerCase() === 'shop', [primarySectionName]);

  const shouldRenderShopTheShowRecirc = useMemo(() => hasOnTheShowLabel && isPrimarySectionShop,
    [hasOnTheShowLabel, isPrimarySectionShop]);

  if (!article || !vertical) {
    return null;
  }
  return (
    <div
      className="body-bottom-recommended"
      data-test="body-bottom-recommended"
      data-testid="body-bottom-recommended"
    >
      {renderEcommerceWidget === NEWS && (
        <Recommended
          additionalClasses={gridBodyDnLClasses}
          isNBCNewsCommerce
          sticky={false}
          url={url.primary}
          data-test="bottom-recommended-news-ecom"
          pageRegion="article-bottom"
        />
      )}
      {renderEcommerceWidget === TODAY && !shouldRenderShopTheShowRecirc && (
        <Recommended
          additionalClasses={gridBodyBotRailRecommendedClasses}
          isTrending
          sticky={false}
          url={url.primary}
          data-test="bottom-recommended-today-ecom"
          pageRegion="article-bottom"
        />
      )}
      {shouldRenderShopTheShowRecirc && (
        <Recommended
          additionalClasses={gridBodyBotRailRecommendedClasses}
          isTrending
          isShopTheShow
          sticky={false}
          url={url.primary}
          data-test="bottom-recommended-today-ecom"
          pageRegion="article-bottom"
        />
      )}
      {
        shouldShowAd(article)
        && !showTaboola
        && (
          <MidresponsiveAd
            data-test="bottom-recommended-midresponsive"
          />
        )
      }
      {shouldRenderTodayGiftGuide && (
        <Recommended
          additionalClasses={classNames(gridBodyClasses, block)}
          isGiftGuide
          sticky={false}
          url={url.primary}
          data-test="bottom-recommended-giftguide"
          pageRegion="article-bottom"
        />
      )}
    </div>
  );
};

BodyBottomRecommended.propTypes = {
  block: PropTypes.string,
  gridBodyClasses: PropTypes.string,
  shouldRenderTodayGiftGuide: PropTypes.bool,
  showTaboola: PropTypes.bool,
};
