import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { FeatureFlagContext } from 'lib/ContextTypes';

/**
 * MostPopularStoryList component to render a list of most popular stories.
 *
 * @param {object} props - The properties object.
 * @param {Array} props.mostPopularStoryListItems - The list of most popular story items.
 * @returns {JSX.Element|null} The MostPopularStoryList component or null if the feature is disabled or there are fewer than 3 items.
 */
const MostPopularStoryList = ({ mostPopularStoryListItems }) => {
  const { 'is-most-popular-module-enabled': isMostPopularModuleEnabled } = useContext(FeatureFlagContext);


  if (!isMostPopularModuleEnabled || !Number.isFinite(mostPopularStoryListItems?.length)
    || mostPopularStoryListItems?.length < 3) {
    return null;
  }

  return (
    <span data-testid="most-popular-list" />
  );
};

MostPopularStoryList.propTypes = {
  mostPopularStoryListItems: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default MostPopularStoryList;
