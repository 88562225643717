import pipe from 'lib/pipe';

import { insertRecommendations } from 'components/Recommended/insert';
import { disableRecommendations } from 'lib/article';
import { insertAdsIfNotNativeAd } from './insertAdsIfNotNativeAd';
import { insertInlineNewsletterModule } from './insertInlineNewsletterModule';
import { insertTaboolaRecoReel } from './insertTaboolaRecoReel';
import { addEcommerce } from './addEcommerce';
import { splitIntoSections } from './splitIntoSections';
import { formatSections } from './formatSections';
import { insertShoppingCart } from './insertShoppingCart';
import { transformLegacyLinks } from './transformLegacyLinks';
import { insertEndmark } from './insertEndmarkFlag';
import { populateQuickTakesEmbed } from './populateQuickTakesEmbed';
import { transformSponsoredLinks } from './transformSponsoredLinks';
import { transformJumpLinks } from './transformJumpLinks';
import { removeProductSpecMarkers } from './removeProductSpecMarkers';

/**
*
* @param {object} params
* @param {boolean} params.adsEnabled
* @param {Article} params.article
* @param {Markup[]} params.articleBody
* @param {boolean} params.breakingNews
* @param {boolean} params.ecommerceEnabled
* @param {boolean} params.nativeAd
* @param {string} params.path
* @param {VerticalType} params.vertical
* @returns
*/
export function getSections(
  {
    adsEnabled,
    article,
    articleBody: body,
    breakingNews,
    ecommerceEnabled,
    isLiveBlog,
    nativeAd,
    path,
    taboolaRecoReelEnabled,
    vertical,
    isShoppable,
    gateAccess,
  },
) {
  const disableReco = disableRecommendations(article, vertical);
  const {
    datePublished: articlePublishDate,
    ecommerceEnabled: isArticleEcommerceEnabled,
    url: { canonical: articleCanonicalUrl },
    source,
  } = article;

  const sourceName = source?.name || source?.organization?.name;

  const sectionPipeline = pipe(
    removeProductSpecMarkers,
    transformLegacyLinks,
    transformJumpLinks,
    transformSponsoredLinks(article.ecommerceMetadata?.trackingId),
    // flat array of data at this point, straight from the api
    insertAdsIfNotNativeAd(nativeAd, adsEnabled),
    insertRecommendations(article, disableReco),
    insertInlineNewsletterModule(article, vertical, isLiveBlog, breakingNews),
    insertTaboolaRecoReel({
      adsEnabled,
      breakingNews,
      isLiveBlog,
      taboolaRecoReelEnabled,
      vertical,
    }),
    populateQuickTakesEmbed(),
    insertShoppingCart({
      isShoppable,
      article,
      vertical,
    }),
    addEcommerce(ecommerceEnabled),
    insertEndmark,
    // below changes up the array shape, no longer a flat array. this is the actual "getSections"
    splitIntoSections,
    // formatSections below also turns each `items` subarray into React components
    // we need to pass article to formatSection because we need to know an article's publish date
    // when building affiliate links
    formatSections({
      articleTrackingId: article.ecommerceMetadata?.trackingId,
      articleId: article.id,
      articlePublishDate,
      isArticleEcommerceEnabled,
      articleCanonicalUrl,
      isShoppable,
      path,
      vertical,
      gateAccess,
      sourceName,
    }),
  );

  return sectionPipeline(Array.from(body));
}
