import { isLiveBlogPath } from 'lib/liveBlog';
import { getBodyItemComponent } from '../getBodyItemComponent';
import { isFullWidth as isFullWidthItem } from './isFullWidth';

const TABOOLA = 'taboola';
const BOX_RAIL = 'boxrail';
const BOX_FLEX = 'boxflex';

/**
 * Determine if an item is a content element.
 * @param {*} item
 */
const isContentElement = (item) => !!item
  && (item.element === 'p'
    || item.element === 'ul'
    || item.element === 'ol'
    || (item.presentation?.size
      && ['small', 'medium'].includes(item.presentation.size)));

/**
 *
 * @param {*} section
 * @returns {number}
 */
const getContentCount = (section) => section.filter(isContentElement).length;

/**
 * Get a component wrapper function for a body item.
 * @param {object} props
 * @param {string} props.path
 * @param {string} props.articlePublishDate
 * @param {VerticalType} props.vertical
 * @param {boolean} props.lazyLoadSection
 * @param {boolean} props.isArticleEcommerceEnabled
 * @param {string} props.articleCanonicalUrl
 * @param {string} props.articleId
 * @param {string} props.articleTrackingId
 * @param {Array} props.sectionMarkup
 * @returns {Function}
 */
const getComponentWrapper = ({
  path,
  articlePublishDate,
  vertical,
  lazyLoadSection,
  isArticleEcommerceEnabled,
  articleCanonicalUrl,
  articleId,
  articleTrackingId,
  sectionMarkup,
  sourceName,
}) => (item, i) =>
// eslint-disable-next-line implicit-arrow-linebreak
  getBodyItemComponent({
    isArticleEcommerceEnabled,
    articleCanonicalUrl,
    item,
    i,
    path,
    articlePublishDate,
    vertical,
    lazyLoadSection,
    articleId,
    articleTrackingId,
    sectionMarkup,
    sourceName,
  });

/**
 * Get ads to display based on content length.
 * @param {number} contentLength
 * @returns {Array}
 */
const getAdsToDisplay = (contentLength) => {
  // 0-2 paragraphs: no ads
  if (contentLength < 3) return [];

  // 3-4 paragraphs: boxrail only
  if (contentLength < 5) return [BOX_RAIL];

  // 5-6 paragraphs: taboola & boxrail
  if (contentLength < 7) return [TABOOLA, BOX_RAIL];

  // 7+ paragraphs: taboola & boxflex
  return [TABOOLA, BOX_FLEX];
};

/**
 *
 * @param {string} path
 * @param {string} articlePublishDate
 * @param {string} articleCanonicalUrl
 * @param {VerticalType} vertical
 * @param {isShoppable} boolean
 * @param {isArticleEcommerceEnabled} boolean
 * @returns
 */
export function formatSections({
  path,
  articlePublishDate,
  isArticleEcommerceEnabled,
  articleCanonicalUrl,
  vertical,
  isShoppable,
  articleId,
  articleTrackingId,
  gateAccess = true,
  sourceName,
}) {
  return (sections) => sections.map((sec, i) => {
    let section = [];
    if (gateAccess) {
      section = sec;
    } else if (i === 0) {
      // grab only the first two sections for gated articles
      section = [sec[0], sec[1]];
    }

    const contentCount = getContentCount(sec);
    const isFullWidth = sec.every((s) => s.presentation?.size === 'edgetoedge');
    // couldn't simply reuse the above full width measure to hide the right rail
    // because that one also appears to affect left margin styles, etc.
    const hideRightRail = sec.every((s) => isFullWidthItem(s));
    const ads = getAdsToDisplay(contentCount);
    const isLiveBlog = isLiveBlogPath(path);
    // only consider eager loading of images for the first section
    const lazyLoadSection = i > 0;

    const formatted = {
      items: section.map(
        getComponentWrapper({
          path,
          articlePublishDate,
          vertical,
          lazyLoadSection,
          isArticleEcommerceEnabled,
          articleCanonicalUrl,
          articleId,
          articleTrackingId,
          sectionMarkup: section,
          sourceName,
        }),
      ),
      isFullWidth,
      hideRightRail,
      ad: {
        display: false,
      },
      taboola: false,
    };

    if (!ads.length) return formatted;

    if (!isShoppable && (ads.includes(TABOOLA) || isLiveBlog)) {
      formatted.taboola = true;
    }

    if (ads.includes(BOX_RAIL)) {
      formatted.ad.display = true;
      formatted.ad.slot = BOX_RAIL;
    }

    if (ads.includes(BOX_FLEX)) {
      formatted.ad.display = true;
      formatted.ad.slot = BOX_FLEX;
    }

    return formatted;
  });
}
