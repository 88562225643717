import React, {
  useState, useRef, useEffect, useCallback,
} from 'react';
import PropTypes from 'prop-types';
import Link from 'components/Link';
import { Save } from 'components/SocialShareMenu/Save';
import classNames from 'classnames';

import styles from './styles.module.scss';

/**
 * FeaturedRecipes component
 * @param {object} props - The component props.
 * @param {Array} props.embeddedRecipes - The list of embedded recipes.
 * @returns {React.ReactNode} The rendered component.
 */
const FeaturedRecipes = ({ embeddedRecipes = [] }) => {
  const [hasOverflow, setHasOverflow] = useState(false);
  const [atBottom, setAtBottom] = useState(false);
  const scrollRef = useRef(null);

  const checkOverflow = useCallback(() => {
    const { scrollHeight, clientHeight } = scrollRef.current;
    setHasOverflow(scrollHeight > clientHeight);
  }, []);

  const handleScroll = useCallback(() => {
    const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;
    setAtBottom(scrollTop + clientHeight >= scrollHeight - 1);
  }, []);

  useEffect(() => {
    checkOverflow();

    const scrollElement = scrollRef.current;
    scrollElement.addEventListener('scroll', handleScroll);

    return () => {
      scrollElement.removeEventListener('scroll', handleScroll);
    };
  }, [checkOverflow, handleScroll]);

  return (
    <div className={classNames(styles.wrapper, hasOverflow && !atBottom ? styles.fade : '')} data-activity-map="featured-recipes" data-testid="featuredRecipes">
      <div className={styles.textContent} ref={scrollRef} data-testid="recipeText">
        <h1 className={styles.header}>Recipes Featured in this Article</h1>
        <ul>
          {embeddedRecipes.map((item) => (
            <li key={item.recipe.id} className={styles.listItem}>
              <Save contentId={item.recipe.id} contentType="recipe" additionalClasses={styles.save} />
              <Link className={styles.link} to={item.recipe.url.primary} target="_blank" rel="noreferrer">
                {item.recipe.headline.primary}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

FeaturedRecipes.propTypes = {
  embeddedRecipes: PropTypes.arrayOf(PropTypes.shape({})),
};

export { FeaturedRecipes };
